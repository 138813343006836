<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <template v-slot:activator="{ on, attrs }">
      <slot name="activator" dark :attrs="attrs" :on="on">
        <v-btn color="primary" dark v-bind="attrs" v-on="on">
          Open Dialog
        </v-btn>
      </slot>
    </template>
    <v-card>
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Confirm action</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col col="12">
              <h3>
                Add manual subscription for a Business:
                <b>{{ business.name }}</b>
              </h3>
            </v-col>
          </v-row>
          <v-row>
            <v-col col="12">
              <v-text-field
                label="User email "
                color="primary"
                type="text"
                v-model="emailAddress"
                prepend-icon="mdi-mail"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col col="12">
              <v-menu
                v-model="dateMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="subscriptionStartDate"
                    label="Subscription Start Date (If other than today) "
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="subscriptionStartDate"
                  @input="dateMenu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row>
            <v-col col="12">
              <v-select
                v-model="selectedPeriod"
                :items="['1 month', '3 months', '6 months', '1 year']"
                label="Period"
                prepend-icon="mdi-timer-sand"
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col col="12">
              <v-text-field
                label="Notes "
                color="primary"
                type="text"
                v-model="notes"
                prepend-icon="mdi-note"
              />
            </v-col>
          </v-row>
          <app-select-component :business="business" @selectedAppEvent="selectedAppEvent"></app-select-component>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="dialog = false">
          Close
        </v-btn>
        <v-btn color="blue darken-1" text @click="createManualSubscription">
          Add Subscription
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
// import config from "../../config/config";
import businessService from "../services/subscription/business";
import AppSelectComponent from "./AppSelectComponent.vue";

const getPeriod = (description) => {
  if (description == "1 month") return "P1M";
  if (description == "3 months") return "P3M";
  if (description == "6 months") return "P6M";
  if (description == "1 year") return "P1Y";
  return "P1Y";
};

export default {
  components: {
    AppSelectComponent,
  },
  props: {
    business: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
      numberOfDays: null,
      selectedPeriod: "1 year",
      notes: null,
      emailAddress: null,
      dateMenu: false,
      subscriptionStartDate: null,
      selectedApp: null,
    };
  },
  computed: {
    ...mapGetters({
      activeAccount: "realm/active",
      email: "business/email",
    }),
  },
  methods: {
    async createManualSubscription() {
      try {
        this.$notifier.showMessage({
          content: "Updating..Please wait...",
        });
        const appId = this.selectedApp ? this.selectedApp : this.business.appId;
        await businessService.createManualSubscription(
          this.activeAccount.id,
          this.emailAddress,
          this.business.id,
          this.subscriptionStartDate,
          getPeriod(this.selectedPeriod),
          this.notes,
          appId,
        );
        this.$notifier.showMessage({
          content: "Updated to premium",
        });
        this.dialog = false;
      } catch (error) {
        this.$notifier.showMessage({
          content:
            "Update to premium failed. Error: " + error.message || "unknown",
        });
      }
    },
    selectedAppEvent(app) {
      this.selectedApp = app;
    },
  },
  mounted() {
    this.emailAddress = this.email || null;
  },
};
</script>
