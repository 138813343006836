<template>
  <div class="partner-home">
    <div class="app-loader" v-if="businessFetchWaiting">
      <span class="app-loader__message">Loading...</span>
      <v-progress-circular
        class="mx-auto"
        :size="70"
        :width="7"
        indeterminate
      ></v-progress-circular>
    </div>
    <v-container class="partner-home__container" v-else-if="business">
      <v-row>
        <v-col col="3">
          <v-btn text to="/master/customer">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
        </v-col>
        <v-col col="9">
          <div class="actions">
            <v-btn @click="reloadBusinessDetails()" icon color="primary" dark>
              <v-icon>mdi-reload</v-icon>
            </v-btn>
            <stripe-link-dialog :business="business" v-if="activeRealm.stripeBilling">
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" v-bind="attrs" v-on="on">
                  <v-icon>mdi-credit-card</v-icon>
                  Create Link (Stripe)
                </v-btn>
              </template>
            </stripe-link-dialog>
            <razorpay-link-dialog :business="business" v-if="activeRealm.razorpayBilling">
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" v-bind="attrs" v-on="on">
                  <v-icon>mdi-credit-card</v-icon>
                  Create Link (Razorpay)
                </v-btn>
              </template>
            </razorpay-link-dialog>
            <free-trial-extend-dialog :business="business">
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" v-bind="attrs" v-on="on">
                  <v-icon>mdi-plus</v-icon>
                  Free Trial
                </v-btn>
              </template>
            </free-trial-extend-dialog>

            <add-manual-subscription-dialog :business="business">
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" v-bind="attrs" v-on="on">
                  <v-icon>mdi-plus</v-icon>
                  Manual Subscription
                </v-btn>
              </template>
            </add-manual-subscription-dialog>
            <block-business-dialog
              :business="business"
              :currentState="business.blocked ? 'blocked' : 'unblocked'"
            ></block-business-dialog>
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon color="primary" dark v-bind="attrs" v-on="on">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item small>
                  <v-list-item-title>Reset (All)</v-list-item-title>
                </v-list-item>
                <v-list-item small>
                  <v-list-item-title>Reset</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col col="12">
          <v-card title="View Business">
            <v-card-title>
              Business: {{ business.name }} &nbsp;
              <v-chip color="success" v-if="business.activeProductId">
                Premium
                <span v-if="!business.plan"> &nbsp;Trial</span>
              </v-chip>
            </v-card-title>
            <v-list class="transparent">
              <v-list-item>
                <v-list-item-title> User Id </v-list-item-title>
                <v-list-item-subtitle class="text-right">
                  {{ user.id }}
                </v-list-item-subtitle>
              </v-list-item>
              <v-list-item>
                <v-list-item-title> Business Id </v-list-item-title>
                <v-list-item-subtitle class="text-right">
                  {{ business.id }}
                </v-list-item-subtitle>
              </v-list-item>

              <v-list-item>
                <v-list-item-title> Owner </v-list-item-title>
                <v-list-item-subtitle class="text-right">
                  {{ business.email }}
                  <change-owner-email :business="business"></change-owner-email>
                </v-list-item-subtitle>
              </v-list-item>

              <v-list-item>
                <v-list-item-title> Phone </v-list-item-title>
                <v-list-item-subtitle class="text-right">
                  {{ business.phone }}
                </v-list-item-subtitle>
              </v-list-item>

              <v-list-item>
                <v-list-item-title> Address </v-list-item-title>
                <v-list-item-subtitle class="text-right">
                  {{ business.address }}
                </v-list-item-subtitle>
              </v-list-item>

              <v-list-item>
                <v-list-item-title> Current Plan </v-list-item-title>
                <v-list-item-subtitle class="text-right">
                  <div>
                    {{ (business.activeProductId || "").capit }} -
                    {{ getPlanName(business.plan) || "Trial" }}
                  </div>
                  <div class="text-small" v-if="business.activePlanExpiry">
                    expires:
                    {{ getFormattedDate(business.activePlanExpiry) }}
                  </div>
                </v-list-item-subtitle>
              </v-list-item>

              <v-list-item>
                <v-list-item-title> Country </v-list-item-title>
                <v-list-item-subtitle class="text-right">
                  {{ business.country }}
                </v-list-item-subtitle>
              </v-list-item>

              <v-list-item>
                <v-list-item-title> Business Created </v-list-item-title>
                <v-list-item-subtitle class="text-right">
                  <div>{{ getFormattedDate(business.createdDate) }}</div>
                </v-list-item-subtitle>
              </v-list-item>

              <v-list-item>
                <v-list-item-title> Storefront Name </v-list-item-title>
                <v-list-item-subtitle class="text-right">
                  <div>
                    {{ business.storeName }} &nbsp;
                    <change-store-name
                      :business="business"
                      v-if="business.storeName"
                    ></change-store-name>
                    <!-- <v-btn icon @click="changeStoreName">
                      <v-icon>mdi-pencil</v-icon></v-btn
                    > -->
                  </div>
                </v-list-item-subtitle>
              </v-list-item>

              <v-list-item>
                <v-list-item-title> Staff count </v-list-item-title>
                <v-list-item-subtitle class="text-right">
                  <div>{{ business.staffCount }}</div>
                </v-list-item-subtitle>
              </v-list-item>

              <v-list-item>
                <v-list-item-title> Customer count </v-list-item-title>
                <v-list-item-subtitle class="text-right">
                  <div>{{ business.customerCount }}</div>
                </v-list-item-subtitle>
              </v-list-item>

              <!-- <v-list-item>
                <v-list-item-title> Free trial remaining</v-list-item-title>
                <v-list-item-subtitle class="text-right">
                  <div>{{ freeTrialRemaining }}</div>
                </v-list-item-subtitle>
              </v-list-item> -->

              <v-list-item v-if="business.subscription">
                <v-list-item-title> Notes </v-list-item-title>
                <v-list-item-subtitle class="text-right">
                  <div>{{ business.subscription.notes }}</div>
                </v-list-item-subtitle>
              </v-list-item>

              <v-list-item>
                <v-list-item-title> Pricing </v-list-item-title>
                <v-list-item-subtitle class="text-right">
                  <div>Yearly: {{ getYearlyPrice(business) }}</div>
                  <div>Monthly: {{ getMonthlyPrice(business) }}</div>
                  <div>(Segment - {{ business.pricingSegmentId }})</div>
                </v-list-item-subtitle>
              </v-list-item>
            </v-list>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-row align="center" justify="center">
            <v-col cols="12">
              <v-card>
                <v-card-title> Subscriptions </v-card-title>
                <subscriptions-list
                  :subscriptions="business.subscriptions"
                  :business="business"
                ></subscriptions-list>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import businessService from "../../services/subscription/business";
import StripeLinkDialog from "../../components/StripeLinkDialog.vue";
import RazorpayLinkDialog from "../../components/RazorpayLinkDialog.vue";
import FreeTrialExtendDialog from "../../components/FreeTrialExtendDialog.vue";
import AddManualSubscriptionDialog from "../../components/AddManualSubscriptionDialog.vue";
import BlockBusinessDialog from "../../components/BlockBusinessDialog.vue";
import ChangeStoreName from "../../components/ChangeStoreName.vue";
import ChangeOwnerEmail from "../../components/ChangeOwnerEmail.vue";
import SubscriptionsList from "../../components/subscriptions-list.vue";

const partnerBusinessService = {};

function copyToClipboard(text) {
  var dummy = document.createElement("textarea");
  // to avoid breaking orgain page when copying more words
  // cant copy when adding below this code
  // dummy.style.display = 'none'
  document.body.appendChild(dummy);
  //Be careful if you use texarea. setAttribute('value', value), which works with "input" does not work with "textarea". – Eduard
  dummy.value = text;
  dummy.select();
  document.execCommand("copy");
  document.body.removeChild(dummy);
}

const isActive = (subscription) => {
  const now = Date.now();
  return now <= subscription.expiryTime && !subscription.replacedByAnotherSub;
};

export default {
  components: {
    StripeLinkDialog,
    RazorpayLinkDialog,
    FreeTrialExtendDialog,
    AddManualSubscriptionDialog,
    BlockBusinessDialog,
    ChangeStoreName,
    ChangeOwnerEmail,
    SubscriptionsList,
  },
  data() {
    return {
      premiumLink: "",
      business: null,
      businessFetchWaiting: true,
      subscriptionHeaders: [
        { text: "Status", align: "start", value: "status" },
        { text: "Start Time", align: "start", value: "startTime" },
        { text: "Expiry Time", value: "expiryTime" },
        { text: "Plan", value: "plan" },
        { text: "Provider", value: "providerId" },
        { text: "Identifier", value: "identifier" },
        { text: "JSON", value: "json" },
        { text: "", value: "actions" },
      ],
    };
  },
  computed: {
    ...mapGetters({
      account: "realm/active",
      user: "user/user",
      activeRealm: "realm/active",
      realms: "realm/realms",
    }),
    currentBusinessId() {
      return this.$route.params.bId || null;
    },
    isWhitelabel() {
      return this.account.type == "whitelabel";
    },
    freeTrialRemaining() {
      return 2 - this.business.subscriptions.filter((x) => x.providerId == "FREE_TRIAL").length;
    }
  },
  methods: {
    ...mapActions({
      getBusinessFromList: "business/getBusinessFromList",
      updateBusinessOnRefetch: "business/updateBusinessOnRefetch",
    }),
    getFormattedDate(date) {
      return date ? moment(date).format("ll") : "-";
    },
    async loadBusinessDetails() {
      const business = await this.getBusinessFromList(this.currentBusinessId);
      if (business != null) {
        this.business = business;
        this.businessFetchWaiting = false;
      } else {
        this.businessFetchWaiting = true;
        this.business = await businessService.getBusinessDetails(
          this.account.id,
          this.currentBusinessId
        );
        this.updateBusinessOnRefetch(this.business);
        this.businessFetchWaiting = false;
      }
    },
    async reloadBusinessDetails() {
      // this.fetchBusiness(this.account.id, this.currentBusinessId);
      this.businessFetchWaiting = true;
      this.business = await businessService.getBusinessDetails(
        this.account.id,
        this.currentBusinessId
      );
      this.updateBusinessOnRefetch(this.business);
      this.businessFetchWaiting = false;
    },
    makePremiumDialog() {},
    async createPremiumLink() {
      const link = await partnerBusinessService.getPremiumLinkForBusiness(
        this.account.id,
        this.currentBusinessId
      );
      this.premiumLink = link;

      copyToClipboard(link);

      this.$notifier.showMessage({ content: "Link copied", color: "success" });
    },
    reportBusiness() {
      if (window.HelpCrunch) {
        // window.HelpCrunch("updateUserData", {
        //   currentPage: "Report Business",
        //   businessIdToReport: this.business.id,
        //   businessName: this.business.name,
        //   businessPlan: this.business.activePlanId,
        //   activeProductId: this.business.activeProductId,
        //   businessCreatedAt: this.getFormattedDate(this.business.createdDate),
        // });
        window.HelpCrunch("openChat");
      }
    },
    getTableItems() {
      return this.business.subscriptions;
      // if (this.business && this.business.subscription)
      //   return [this.business.subscription];
      // return [];
    },
    getJson(item) {
      return JSON.stringify(item, null, 4);
    },
    getProviderName(id) {
      if (id == "FREE_TRIAL") return "Free Trail";
      if (id == "STRIPE_SUBSCRIPTION") return "Stripe";
      if (id == "PLAY_BILLING") return "Google Play";
      if (id == "Manual") return "Manual";
      if (id == "RAZORPAY_NON_RECURRING") return "Razorpay (One Time)";
      return id;
    },
    getSubscriptionLink(sub) {
      if (sub.providerId == "STRIPE_SUBSCRIPTION")
        return (
          "https://dashboard.stripe.com/subscriptions/" + sub.providerData.id
        );
      if (sub.providerId == "PLAY_BILLING")
        return (
          "https://play.google.com/console/u/0/developers/6773541816598877702/orders/" +
          sub.providerData.orderId
        );
      return "#";
    },
    getSubscriptionIdentifier(sub) {
      if (sub.providerId == "STRIPE_SUBSCRIPTION") return sub.providerData.id;
      if (sub.providerId == "PLAY_BILLING") return sub.providerData.orderId;
      return "#";
    },
    getPlanName(planId) {
      if (!planId) return "N/A";
      return planId == "p1m" ? "Monthly" : "Yearly";
    },
    getStatus(sub) {
      if (isActive(sub)) return "Active";
      else return "Expired";
    },
    getStatusColor(sub) {
      if (isActive(sub)) return "green";
      else return "gray";
    },
    getRemainingDays(sub) {
      return ((sub.expiryTime - Date.now()) / (1000 * 60 * 60 * 24)) | 0;
    },
    isStripeSubscription(sub) {
      if (sub.providerId == "STRIPE_SUBSCRIPTION") return true;
      return false;
    },
    isRefundable(sub) {
      // If provider is google play or stripe and is mutable / and the subscription is active
      if (
        isActive(sub) &&
        (sub.providerId == "STRIPE_SUBSCRIPTION" ||
          sub.providerId == "PLAY_BILLING")
      ) {
        return true;
      }
      return false;
    },
    isActive(sub) {
      return isActive(sub);
    },
    getYearlyPrice(business) {
      let price = (business.pricing.stripe || business.pricing.play_billing)
        .p1y;
      return `${price.price} ${price.currency}`;
    },
    getMonthlyPrice(business) {
      let price = (business.pricing.stripe || business.pricing.play_billing)
        .p1m;
      return `${price.price} ${price.currency}`;
    },
  },
  mounted() {
    this.loadBusinessDetails();
  },
};
</script>

<style lang="scss">
.home {
  min-height: 100%;
}

.centered-content {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}

.actions {
  display: flex;
  justify-content: flex-end;

  button {
    margin-left: 0.33rem;
  }
}

.v-list-item__subtitle {
  font-size: 1rem;
}
</style>
