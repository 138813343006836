<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <template v-slot:activator="{ on, attrs }">
      <slot name="activator" :attrs="attrs" :on="on">
        <v-btn icon v-bind="attrs" v-on="on">
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
      </slot>
    </template>
    <v-card>
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Confirm action</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col col="12">
              <h3>
                Change the owner of this store - <b>{{ business.name }}</b
                >?
                <!-- Please make sure you have collected a payment of
                <strong>{{ yearlyPriceCurrency }} {{ yearlyPrice }}</strong>
                from this business -->
              </h3>
            </v-col>
          </v-row>
          <v-row>
            <v-col col="12">
              <h3>
                Prerequisites: <br />
                1. The new email should be registered on the platform
                <br />
                2. An email confirmation from the original email is received to
                transfer the ownership 3. If not sure, or the email shared looks
                fabricatedd, send a random OTP to the email and verify the same
                via chat.
              </h3>
              <br />
              <h3 color="error">
                Please explain this to the user before performing the action
                <br />
                1. Existing owner will be demoted to a manager role
                <br />
                2. If the new owner email is a staff inside the business, they
                will be removed from staff and upgraded to be the owner.
              </h3>
            </v-col>
          </v-row>
          <v-row>
            <v-col col="12">
              <v-text-field
                label="Current owner email which you have taken confirmation from "
                v-model="currentEmailAddress"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col col="12">
              <v-text-field
                label="Enter new email for which you want to transfer "
                v-model="newEmailAddress"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col col="12">
              <v-checkbox
                label="I have verified that the transfer request came from the current owner email"
                v-model="isEmailVerified"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="dialog = false">
          Close
        </v-btn>
        <v-btn
          v-if="isEmailVerified"
          color="blue darken-1"
          text
          @click="transferBusinessOwnership"
        >
          Confirm Change
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import config from "../../config/config";
import businessService from "../services/subscription/business";

export default {
  props: {
    business: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isEmailVerified: false,
      dialog: false,
      currentEmailAddress: "",
      newEmailAddress: "",
      storeName: "",
      notifications: false,
      sound: true,
      widgets: false,
      rules: [
        (value) => !!value || "Required.",
        (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
      ],
      selectedDiscountCode: null,
      selectedPeriod: "1 year",
    };
  },
  computed: {
    ...mapGetters({
      activeAccount: "realm/active",
    }),
    yearlyPrice() {
      return 1990;
    },
    yearlyPriceCurrency() {
      return "SAR";
    },
    isWhitelabel() {
      return !!config.whitelabelMode;
    },
  },
  methods: {
    async transferBusinessOwnership() {
      this.dialog = false;
      try {
        this.$notifier.showMessage({
          content: "Transferring..Please wait...",
        });
        await businessService.changeOwner(
          this.activeAccount.id,
          this.business.id,
          this.currentEmailAddress,
          this.newEmailAddress
        );
        this.$notifier.showMessage({
          content:
            "Ownership transfer successfully completed to email: " +
            this.newEmailAddress,
          color: "green",
          timeout: -1,
        });
      } catch (error) {
        this.$notifier.showMessage({
          content:
            "Transfer failed. Contact Parag ASAP with the details if user cannot access the business. Error: " +
              error.message || "unknown",
          timeout: -1,
        });
      }
    },
  },
};
</script>
