<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <template v-slot:activator="{ on, attrs }">
      <slot name="activator" dark :attrs="attrs" :on="on">
        <v-btn color="primary" dark v-bind="attrs" v-on="on">
          Open Dialog
        </v-btn>
      </slot>
    </template>
    <v-card>
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Confirm action</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-container>
          <v-row v-if="createdLink">
            <v-alert dense outlined type="success"
              >Successfully created subscribe link: <b>{{ createdLink }}</b>
              <v-icon @click="copyCreatedLink()"
                >mdi-content-copy</v-icon
              ></v-alert
            >
          </v-row>
          <v-row>
            <v-col col="12">
              <h3>
                Create a subscribe link via Stripe for Business:
                <b>{{ business.name }}</b>
              </h3>
            </v-col>
          </v-row>
          <v-row>
            <v-col col="12">
              <v-text-field
                label="User email "
                color="primary"
                type="text"
                v-model="emailAddress"
                prepend-icon="mdi-mail"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col col="12">
              <v-combobox
                clearable
                v-model="offerCode"
                :items="offerCodes"
                label="Enter offer code if any "
                color="primary"
                type="text"
                prepend-icon="mdi-tag"
              ></v-combobox>
            </v-col>
          </v-row>
          <v-row v-if="offerCode">
            <v-col col="12">
              <v-checkbox
                v-model="yearlyOnly"
                label="Restrict offer to yearly plan only"
              ></v-checkbox>
            </v-col>
          </v-row>
          <app-select-component :business="business" @selectedAppEvent="selectedAppEvent"></app-select-component>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="dialog = false">
          Close
        </v-btn>
        <v-btn color="blue darken-1" text @click="createPremiumLink">
          Create Link
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import config from "../../config/config";
import businessService from "../services/subscription/business";
import AppSelectComponent from "./AppSelectComponent.vue";
// const getPeriod = (description) => {
//   if (description == "1 month") return "P1M";
//   if (description == "3 months") return "P3M";
//   if (description == "6 months") return "P6M";
//   if (description == "1 year") return "P1Y";
//   return "P1Y";
// };

export default {
  components: {
    AppSelectComponent,
  },
  props: {
    business: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
      offerCode: null,
      yearlyOnly: true,
      emailAddress: "",
      notifications: false,
      sound: true,
      widgets: false,
      rules: [
        (value) => !!value || "Required.",
        (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
      ],
      selectedDiscountCode: null,
      selectedPeriod: "1 year",
      createdLink: null,
      offerCodes: [
        "firstYear10",
        "firstYear20",
        "firstYear30",
        "firstYear40",
        "firstYear50",
      ],
      selectedApp: null,
    };
  },
  computed: {
    ...mapGetters({
      activeAccount: "realm/active",
      email: "business/email",
      user: "user/user",
    }),
    yearlyPrice() {
      return 1990;
    },
    yearlyPriceCurrency() {
      return "SAR";
    },
    isWhitelabel() {
      return !!config.whitelabelMode;
    },
  },
  methods: {
    async createPremiumLink() {
      try {
        this.$notifier.showMessage({
          content: "Updating..Please wait...",
        });
        const appId = this.selectedApp ? this.selectedApp : this.business.appId;
        const link = await businessService.createPremiumLink(
          this.activeAccount.id,
          this.emailAddress,
          this.business.id,
          this.offerCode,
          this.yearlyOnly,
          appId,
          this.user.email
        );
        this.createdLink = link;
      } catch (error) {
        this.$notifier.showMessage({
          content:
            "Create Stripe link failed. Error: " + error.message || "unknown",
        });
      }
    },
    copyCreatedLink() {
      navigator.clipboard.writeText(this.createdLink);
      this.$notifier.showMessage({
        content: "Copied",
      });
    },
    selectedAppEvent(app) {
      this.selectedApp = app;
    },
  },
  mounted() {
    if (this.email) this.emailAddress = this.email;
  },
};
</script>
