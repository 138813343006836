<template>
    <v-container>
        <v-radio-group v-model="selected" row v-on:change="selectedApp">
            <v-radio v-for="(app, i) in activeRealm.enabledApps" :key="i" :label="app.toUpperCase()" :value="app">
            </v-radio>
        </v-radio-group>
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    props: {
        business: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            selected: '',
        }
    },
    computed: {
        ...mapGetters({
            activeRealm: 'realm/active',
        }),
    },
    methods: {
        selectedApp() {
            this.$emit('selectedAppEvent', this.selected);
        }
    },
    mounted () {
        if(this.business) this.selected = this.business.appId;
    }
}
</script>