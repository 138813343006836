<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <template v-slot:activator="{ on, attrs }">
      <slot name="activator" dark :attrs="attrs" :on="on">
        <v-btn v-if="currentState == 'blocked'" dark v-bind="attrs" v-on="on">
          <v-icon>mdi-cancel</v-icon>
          Unblock
        </v-btn>
        <v-btn v-else dark color="error" v-bind="attrs" v-on="on">
          <v-icon>mdi-cancel</v-icon>
          Block
        </v-btn>
      </slot>
    </template>
    <v-card>
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Confirm action</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col col="12">
              <h3>
                {{ this.currentState == "blocked" ? "Unblock" : "Block" }} this
                business - <b>{{ business.name }}</b
                >?
                <!-- Please make sure you have collected a payment of
                <strong>{{ yearlyPriceCurrency }} {{ yearlyPrice }}</strong>
                from this business -->
              </h3>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="dialog = false">
          Close
        </v-btn>
        <v-btn
          color="blue darken-1"
          v-if="currentState == 'unblocked'"
          text
          @click="confirmBlockBusiness"
        >
          Confirm Block
        </v-btn>
        <v-btn
          color="blue darken-1"
          v-if="currentState == 'blocked'"
          text
          @click="confirmUnblockBusiness"
        >
          Confirm Unblock
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import config from "../../config/config";
import businessService from "../services/subscription/business";

export default {
  props: {
    business: {
      type: Object,
      required: true,
    },
    currentState: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
      enteredEmail: "",
      notifications: false,
      sound: true,
      widgets: false,
      rules: [
        (value) => !!value || "Required.",
        (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
      ],
      selectedDiscountCode: null,
      selectedPeriod: "1 year",
    };
  },
  computed: {
    ...mapGetters({
      activeAccount: "realm/active",
    }),
    yearlyPrice() {
      return 1990;
    },
    yearlyPriceCurrency() {
      return "SAR";
    },
    isWhitelabel() {
      return !!config.whitelabelMode;
    },
  },
  methods: {
    async confirmBlockBusiness() {
      this.dialog = false;
      try {
        this.$notifier.showMessage({
          content: "Blocking..Please wait...",
        });
        await businessService.updateBusinessBlockStatus(
          this.activeAccount.id,
          this.business.id,
          true
        );
        this.$notifier.showMessage({
          content: "Blocked the business",
        });
      } catch (error) {
        this.$notifier.showMessage({
          content:
            "Blocking the business failed. Error: " + error.message ||
            "unknown",
        });
      }
    },
    async confirmUnblockBusiness() {
      this.dialog = false;
      try {
        this.$notifier.showMessage({
          content: "Unblocking..Please wait...",
        });
        await businessService.updateBusinessBlockStatus(
          this.activeAccount.id,
          this.business.id,
          false
        );
        this.$notifier.showMessage({
          content: "Unblocked the business",
        });
      } catch (error) {
        this.$notifier.showMessage({
          content:
            "Unblocking the business failed. Error: " + error.message ||
            "unknown",
        });
      }
    },
  },
};
</script>
