<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <template v-slot:activator="{ on, attrs }">
      <slot name="activator" dark :attrs="attrs" :on="on">
        <v-btn color="primary" dark v-bind="attrs" v-on="on">
          Open Dialog
        </v-btn>
      </slot>
    </template>
    <v-card>
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Confirm action</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col col="12">
              <h3>
                Add an extended free trial for Business:
                <b>{{ business.name }}</b>
              </h3>
            </v-col>
          </v-row>
          <v-row>
            <v-col col="12">
              <v-text-field
                label="User email "
                color="primary"
                type="text"
                v-model="emailAddress"
                prepend-icon="mdi-mail"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col col="12">
              <v-text-field
                label="Enter free trial number of days (Min: 1, Max: 90) "
                v-model.number="numberOfDays"
                hide-details
                type="number"
              />
            </v-col>
          </v-row>
          <app-select-component :business="business" @selectedAppEvent="selectedAppEvent"></app-select-component>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="dialog = false">
          Close
        </v-btn>
        <v-btn color="blue darken-1" text @click="createFreeTrial">
          Add Free Trial
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import config from "../../config/config";
import businessService from "../services/subscription/business";
import AppSelectComponent from "./AppSelectComponent.vue";

export default {
  components: {
    AppSelectComponent,
  },
  props: {
    business: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
      numberOfDays: 0,
      emailAddress: null,
      selectedApp: null,
    };
  },
  computed: {
    ...mapGetters({
      activeAccount: "realm/active",
      email: "business/email",
    }),
    yearlyPrice() {
      return 1990;
    },
    yearlyPriceCurrency() {
      return "SAR";
    },
    isWhitelabel() {
      return !!config.whitelabelMode;
    },
  },
  methods: {
    async createFreeTrial() {
      try {
        this.$notifier.showMessage({
          content: "Updating..Please wait...",
        });
        const appId = this.selectedApp ? this.selectedApp : this.business.appId;
        await businessService.createFreeTrialExtension(
          this.activeAccount.id,
          this.business.id,
          this.emailAddress,
          this.numberOfDays,
          appId,
        );
        this.$notifier.showMessage({
          content: "Extended Free Trial Successfully",
        });
        this.dialog = false;
      } catch (error) {
        this.$notifier.showMessage({
          content:
            "Create free trial link failed. Error: " + error.message ||
            "unknown",
        });
      }
    },
    selectedAppEvent(app) {
      this.selectedApp = app;
    },
  },
  mounted() {
    this.emailAddress = this.email || null;
  },
};
</script>
