<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <template v-slot:activator="{ on, attrs }">
      <slot name="activator" :attrs="attrs" :on="on">
        <v-btn icon v-bind="attrs" v-on="on">
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
      </slot>
    </template>
    <v-card>
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Confirm action</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col col="12">
              <h3>
                Change store name of this business?
                <!-- Please make sure you have collected a payment of
                <strong>{{ yearlyPriceCurrency }} {{ yearlyPrice }}</strong>
                from this business -->
              </h3>
            </v-col>
          </v-row>
          <v-row>
            <v-col col="12">
              <v-text-field
                label="Enter new name - minimum 6 characters "
                v-model="storeName"
                hide-details
                type="text"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="dialog = false">
          Close
        </v-btn>
        <v-btn color="blue darken-1" text @click="changeStoreName">
          Confirm Change
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import config from "../../config/config";
import businessService from "../services/subscription/business";

export default {
  props: {
    business: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
      enteredEmail: "",
      storeName: "",
      notifications: false,
      sound: true,
      widgets: false,
      rules: [
        (value) => !!value || "Required.",
        (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
      ],
      selectedDiscountCode: null,
      selectedPeriod: "1 year",
    };
  },
  computed: {
    ...mapGetters({
      activeAccount: "realm/active",
    }),
    yearlyPrice() {
      return 1990;
    },
    yearlyPriceCurrency() {
      return "SAR";
    },
    isWhitelabel() {
      return !!config.whitelabelMode;
    },
  },
  methods: {
    async changeStoreName() {
      this.dialog = false;
      try {
        this.$notifier.showMessage({
          content: "Updating..Please wait...",
        });
        await businessService.changeStoreName({
          realmId: this.activeAccount.id,
          businessId: this.business.id,
          oldName: this.business.storeName,
          newName: this.storeName,
        });
        this.$notifier.showMessage({
          content: "Changed successfully",
        });
      } catch (error) {
        this.$notifier.showMessage({
          content:
            "Store name change failed. Error: " + error.message || "unknown",
        });
      }
    },
  },
};
</script>
